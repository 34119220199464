import React from "react";
import "./Destination.scss";
import mapMarker from "../images/location.svg";

export default function Destination(props) {
    return(
        <div className={`destination destination-${props.id}`}>
            <img src={require(`../images/${props.imageUrl}`)} className="destination--thumbnail" alt={props.title} />

            <div className="destination--content">
                <ul className="destination--location">
                    <li className="destination--location-icon"><img src={mapMarker} alt="map marker icon" /></li>
                    <li className="destination--location-name">{props.location}</li>
                    <li className="destination--location-maps"><a href={props.googleMapsUrl} target="_blank" rel="noreferrer">View on Google Maps</a></li>
                </ul>

                <h3 className="destination--title">{props.title}</h3>

                <p className="destination--dates">{props.startDate} - {props.endDate}</p>
                
                <p className="destination--description">{props.description}</p>
            </div>

        </div>
    )
}