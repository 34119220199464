import React from "react";
import "./Journal.scss";
import data from "../data.js";
import Destination from "./Destination";

console.log(data)

const destinationsList = data.map( (destination) => 
    <Destination
        key={destination.id}
        {...destination}
    />
)

export default function Journal() {
    return(
        <div className="journal">
            <div className="container">
               {destinationsList}
            </div>
        </div>
    )
}