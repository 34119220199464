import React from "react";
import "./Header.scss";
import globe from "../images/globe.svg";

export default function Header() {
    return(
        <div className="header">
            <div className="container">
                <div className="header--inner">
                    <img src={globe} className="header--icon" alt="globe icon"/>
                    <p className="header--strapline">my travel journal.</p>
                </div>
            </div>
        </div>
    )
}