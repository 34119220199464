import "./App.scss";
import Header from "./Components/Header";
import Journal from "./Components/Journal";

function App() {
	return (
		<>
			<Header />
			<Journal />
		</>
	);
}

export default App;
